import React from 'react'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import { createRoot } from 'react-dom/client'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { CONTENT_ORIGIN } from './config'

const client = new ApolloClient({
    uri: `${CONTENT_ORIGIN}/graphql`,
    cache: new InMemoryCache(),
})

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <ApolloProvider client={client}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
