import React from 'react'
import Releases from './Releases'
import { useDomain } from '../hooks/useDomain'
import PinosCloud from '../customise/PinosCloud'

const Home: React.FC = () => {
    const domain = useDomain()

    switch (domain) {
        case 'pinos.cloud':
            return <PinosCloud />
        default:
            return <Releases domain={domain} />
    }
}

export default Home
