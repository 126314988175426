import styled from 'styled-components'

export const VerticalItem = styled.li`
    max-width: 500px;
    list-style-type: none;
    padding-top: 12px;
    padding-bottom: 25px;
    a {
        color: ${(props) => props.theme.gray};
        text-decoration: none;
    }
`

export const HorizontalItem = styled.li`
    max-width: 500px;
    list-style-type: none;
    padding: 8px 12px;
    a {
        color: ${(props) => props.theme.gray};
        text-decoration: none;
    }
`

export const HorizontalList = styled.ul`
    width: 85vmin;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const ShowMoreButton = styled.button`
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: ${(props) => props.theme.buttonBackground};
    color: ${(props) => props.theme.buttonText};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.2s ease;

    &:hover {
        background-color: ${(props) => props.theme.buttonHoverBackground};
    }
`

export const StoreCard = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => props.theme.cardBackground};
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 12px;
    padding: 12px 15px;
    width: 100%;
    max-width: 380px;
    text-decoration: none;
    color: ${(props) => props.theme.text};
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease,
        background 0.2s ease;

    &:hover {
        transform: translateY(-4px);
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.9);
        background: ${(props) => props.theme.hoverBackground};
    }
`

export const CallToAction = styled.div`
    padding: 8px 15px;
    background: ${(props) => props.theme.buttonBackground};
    color: ${(props) => props.theme.buttonText};
    font-weight: bold;
    border-radius: 20px;
    font-size: 14px;
    transition:
        background 0.2s ease,
        color 0.2s ease;

    ${StoreCard}:hover & {
        background: ${(props) => props.theme.buttonHoverBackground};
        color: ${(props) => props.theme.buttonHoverText};
    }
`

export const AlbumCover = styled.img`
    width: 100%;
    max-width: 400px;
    padding: 0;
    margin: 0 auto;
    display: block;
`

export const Title = styled.h1`
    font-size: 24px; /* Slightly larger font */
    font-weight: bold;
    margin: 20px 0;
    color: #f0f0f0; /* Softer white */
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
`

export const StoreList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px 8px 0 8px;
    box-sizing: border-box;
    width: 100%;
`

export const StoreLogo = styled.img`
    height: 50px;
    width: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Space between logo and button */
`

export const OfferText = styled.div`
    font-weight: bold;
    font-size: 22px;
    flex-shrink: 0;
    margin-right: 10px; /* Keeps same spacing as StoreLogo would have */
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.text}; /* Use the theme text color */
    padding: 4px 10px;
    border-radius: 6px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-family: 'Inter', sans-serif;
    box-shadow: none;
    backdrop-filter: blur(5px); /* Adds a slight glass effect */
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    color: ${(props) => props.theme.text};
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    overflow-x: hidden;
    margin: 0 auto;
    background-color: ${(props) => props.theme.background};
`
