const isDev: boolean = process.env.NODE_ENV === 'development'

export const API_ORIGIN = isDev
    ? 'http://localhost:8080'
    : 'https://api.gssnhr.com'

export const CONTENT_ORIGIN = isDev
    ? 'http://localhost:1337'
    : 'https://content.gssnhr.com'

export const storeOrder = [
    'spotify',
    'amazonMusic',
    'appleMusic',
    'deezer',
    'tidal',
    'soundcloud',
    'youtubeMusic',
    'youtube',
    'tiktok',
    'instagram',
    'itunesStore',
]

export const storeIcons: Record<string, string> = {
    spotify: '/images/logo_spotify_ondark.svg',
    appleMusic: '/images/logo_applemusic_ondark.svg',
    amazonMusic: '/images/logo_amazonmusic_ondark.svg',
    youtube: '/images/logo_youtube_ondark.svg',
    youtubeMusic: '/images/logo_youtubemusic_ondark.svg',
    deezer: '/images/logo_deezer_ondark.svg',
    tidal: '/images/logo_tidal_ondark.svg',
    tiktok: '/images/logo_tiktok_ondark.svg',
    soundcloud: '/images/logo_soundcloud_ondark.svg',
    instagram: '/images/logo_instagram_ondark.svg',
    itunesStore: '/images/logo_itunes_ondark.svg',
}

export const storeButtonLabels: Record<string, string> = {
    spotify: 'Abspielen',
    appleMusic: 'Abspielen',
    amazonMusic: 'Abspielen',
    youtube: 'Ansehen',
    youtubeMusic: 'Abspielen',
    deezer: 'Abspielen',
    tidal: 'Abspielen',
    tiktok: 'Abspielen',
    soundcloud: 'Abspielen',
    instagram: 'Abspielen',
    itunesStore: 'Herunterladen',
}
