import { useEffect } from 'react'
import { useDomain } from './useDomain'

export const usePageMeta = (
    title: string | null,
    description: string | null
) => {
    const domain = useDomain()

    useEffect(() => {
        if (title !== null && title !== undefined) {
            document.title = `${title}`
        }
        if (description !== null && description !== undefined) {
            const metaDescription = document.querySelector(
                'meta[name="description"]'
            )
            if (metaDescription) {
                metaDescription.setAttribute('content', description)
            } else {
                const meta = document.createElement('meta')
                meta.name = 'description'
                meta.content = description
                document.head.appendChild(meta)
            }
        }
    }, [title, description, domain])
}
