import React, { ReactElement } from 'react'
import { v4 as uuid } from 'uuid'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HorizontalItem, HorizontalList, VerticalItem } from './Item'
import SociaLink from './SocialLink'
import { usePageContext } from './PageContext'

import { ReactComponent as SpotifyIcon } from '../svg/spotify.svg'
import { ReactComponent as YouTubeIcon } from '../svg/youtube.svg'
import { ReactComponent as TikTokIcon } from '../svg/tiktok.svg'
import { ReactComponent as InstagramIcon } from '../svg/instagram.svg'

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    color: ${(props) => props.theme.text};
    font-weight: 300;
`

const FooterLink = styled(Link)`
    color: ${(props) => props.theme.text};
    text-decoration: none;
    font-weight: 300;
`

const FooterText = styled.p`
    font-weight: 300;
    margin: 0;
`

export default function Footer(): ReactElement {
    const pageData = usePageContext()

    const socialLinks = [
        { svg: <YouTubeIcon />, link: pageData?.youtube },
        { svg: <SpotifyIcon />, link: pageData?.spotify },
        { svg: <TikTokIcon />, link: pageData?.tiktok },
        { svg: <InstagramIcon />, link: pageData?.instagram },
    ].filter((link) => link.link) // Filter out empty links

    return (
        <>
            <StyledFooter>
                <HorizontalList>
                    <HorizontalItem>
                        <FooterLink to="/">Landing Page</FooterLink>
                    </HorizontalItem>
                    <HorizontalItem>
                        <FooterLink to="/impressum">Legal Notice</FooterLink>
                    </HorizontalItem>
                </HorizontalList>
                <HorizontalList>
                    {socialLinks.map((socialLink) => (
                        <HorizontalItem key={uuid()}>
                            <SociaLink
                                svg={socialLink.svg}
                                link={socialLink.link}
                            />
                        </HorizontalItem>
                    ))}
                </HorizontalList>
                <HorizontalList>
                    <VerticalItem>
                        <FooterText>{`© ${new Date().getFullYear()} ${
                            pageData?.name || 'GSSNHR Records'
                        }`}</FooterText>
                    </VerticalItem>
                </HorizontalList>
            </StyledFooter>
        </>
    )
}
