import React, { FC, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { PageContext } from './components/PageContext'
import LegalNotice from './components/LegalNotice'
import Footer from './components/Footer'
import { ThemeProvider } from 'styled-components'
import './App.css'
import { useConfig } from './hooks/useConfig'
import { useDomain } from './hooks/useDomain'
import { usePageMeta } from './hooks/usePageMeta'
import Home from './components/Home'

const Releases = lazy(() => import('./components/Releases'))
const Release = lazy(() => import('./components/Release'))

const App: FC = () => {
    const domain = useDomain()
    const { config, theme, loading, error } = useConfig(domain)
    usePageMeta(config && config?.name, null)

    if (loading) {
        return <></>
    }
    if (error) {
        return <div>Error loading configuration: {error.message}</div>
    }

    return (
        <PageContext.Provider value={config}>
            <div
                className="App"
                style={{
                    position: 'relative',
                    backgroundColor: theme.background,
                    color: theme.text,
                    zIndex: 1,
                }}
            >
                <ThemeProvider theme={theme}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Router>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/release/:id"
                                    element={<Release />}
                                />
                                <Route
                                    path="/impressum"
                                    element={<LegalNotice />}
                                />
                            </Routes>
                            <Footer />
                        </Router>
                    </Suspense>
                </ThemeProvider>
            </div>
        </PageContext.Provider>
    )
}

export default App
