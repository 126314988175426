import React from 'react'
import styled from 'styled-components'
import { usePageMeta } from '../hooks/usePageMeta'
import { useDomain } from '../hooks/useDomain'
import { useConfig } from '../hooks/useConfig'

const ImpressumStyled = styled.div`
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
`

const LegalNotice = () => {
    const domain = useDomain()
    const { config } = useConfig(domain)
    usePageMeta(
        config && `${config?.name} | Legal Notice`,
        'Legal Notice for Page for Artists provided by GSSNHR Records'
    )

    return (
        <ImpressumStyled>
            <h1>Legal Notice</h1>
            <p>
                Service provided by:
                <br />
                Thilo Ilg <br />
                Werrastraße 27
                <br />
                60486 Frankfurt am Main
                <br />
                Germany
                <br />
                <br />
                Email: contact@gssnhr.com
                <br />
            </p>
            <h3>Responsible for content</h3>
            <p>Thilo Ilg, address as above.</p>
            <br />
            <h3>Disclaimer</h3>
            <p>
                The content displayed in link trees on this site is provided and
                owned by the respective artists or labels. GSSNHR does not
                assume liability for the accuracy, completeness, or timeliness
                of the displayed content. The responsibility for all content
                lies with the respective rights holders.
            </p>
            <br />
            <h3>Domain Policy</h3>
            <p>
                Any domains registered or used through this service can be
                claimed at any time by the responsible artist or rights holder.
                Upon request, the domain will be transferred free of charge to
                an account of their choosing.
            </p>
            <br />
            <h3>Liability for Links</h3>
            <p>
                Despite careful control, we accept no liability for the content
                of external links. The operators of the linked pages are solely
                responsible for their content.
            </p>
            <br />
            <h3>Copyright Notice</h3>
            <p>
                All content and works on this site are subject to copyright
                laws. Reproduction, processing, distribution, or any form of
                commercialization of such material beyond the scope of copyright
                law requires the prior written consent of the respective author
                or creator.
            </p>
            <br />
            <h3>Spotify Integration</h3>
            <p>
                This application uses the Spotify API but is not endorsed,
                certified, or otherwise approved in any way by Spotify. Spotify
                is the registered trademark of the Spotify Group.
            </p>
            <br />
            <h3>Data Protection</h3>
            <p>
                The use of this website is generally possible without providing
                personal information. If personal data (e.g., name, address,
                email) is collected, this is done on a voluntary basis wherever
                possible. Such data will not be shared with third parties
                without explicit consent.
            </p>
        </ImpressumStyled>
    )
}

export default LegalNotice
