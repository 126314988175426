import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ConfigResponse, Theme } from '../types'

const GET_CONFIG = gql`
    query GetConfig($domain: String!) {
        pages(filters: { domain: { eq: $domain } }) {
            domain
            name
            spotify
            tiktok
            youtube
            instagram
            theme
            producer
            shopify_collection
        }
    }
`

const defaultTheme: Theme = {
    background: '#000',
    text: '#FFF',
    secondaryText: '#AAAAAA',
    border: '#333',
    buttonBackground: '#222',
    buttonText: '#FFF',
    hoverBackground: '#444',
}

export const useConfig = (domain: string) => {
    const { data, loading, error } = useQuery<ConfigResponse>(GET_CONFIG, {
        variables: { domain },
    })

    const config = data?.pages[0] || null

    const theme: Theme = useMemo(() => {
        return config?.theme || defaultTheme
    }, [config])

    return { config, theme, loading, error }
}
