import { useCallback } from 'react'
import { API_ORIGIN } from '../config'

export const useTracking = () => {
    return useCallback(async (documentId: string, domain: string) => {
        try {
            await fetch(`${API_ORIGIN}/tracking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    documentId,
                    domain,
                }),
            })
        } catch (error) {
            console.error('Tracking failed:', error)
        }
    }, [])
}
