import React from 'react'
import {
    StoreList,
    StoreCard,
    StoreLogo,
    CallToAction,
    Title,
    OfferText,
    Container,
} from '../components/Item'
import styled from 'styled-components'

const ProducerContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
`

const PinosCloud: React.FC = () => {
    const handleEmailClick = () => {
        window.location.href = 'mailto:p.bluntslide@protonmail.com'
    }

    return (
        <Container>
            <ProducerContainer>
                <Title>Pinos Cloud Beats</Title>
                <StoreList>
                    <StoreCard
                        href="https://www.beatstars.com/album/171814"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <OfferText>6 Beats 4,99$</OfferText>
                        <CallToAction>Download Now</CallToAction>
                    </StoreCard>
                    <StoreCard
                        href="https://www.beatstars.com/pinoscloudbeats"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <StoreLogo
                            src="/images/logo_beatstars.svg"
                            alt="Beatstars Logo"
                        />
                        <CallToAction>Beatstore</CallToAction>
                    </StoreCard>
                    <StoreCard
                        href="https://www.youtube.com/@pinoscloudbeats"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <StoreLogo
                            src="/images/logo_youtube_ondark.svg"
                            alt="YouTube Logo"
                        />
                        <CallToAction>YouTube</CallToAction>
                    </StoreCard>
                    <StoreCard onClick={handleEmailClick}>
                        <StoreLogo
                            src="/images/logo_email.svg"
                            alt="Email Logo"
                        />
                        <CallToAction>Contact me</CallToAction>
                    </StoreCard>
                </StoreList>
            </ProducerContainer>
        </Container>
    )
}

export default PinosCloud
