import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import { Album, AlbumsData, LinkType } from '../types'
import {
    API_ORIGIN,
    CONTENT_ORIGIN,
    storeButtonLabels,
    storeOrder,
} from '../config'
import {
    AlbumCover,
    CallToAction,
    ShowMoreButton,
    StoreCard,
    StoreList,
    StoreLogo,
    Title,
    Container,
} from './Item'
import { useConfig } from '../hooks/useConfig'
import { useTracking } from '../hooks/useTracking'
import { usePageMeta } from '../hooks/usePageMeta'

const LatestReleaseContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
`

const ReleasesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 0 0 20px 0;
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    box-sizing: border-box;
`

const CoverLink = React.memo(styled(Link)`
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: block;
    line-height: 0;
`)

const CoverImage = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.05);
    }
`

const MerchContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 20px 0;
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    box-sizing: border-box;
`

const MerchItem = styled.a`
    text-decoration: none;
    display: block;
    line-height: 0;
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.05);
    }
`

const GET_RELEASES = gql`
    query GetReleases($domain: String!, $limit: Int!, $start: Int!) {
        albums(
            filters: { artists: { page: { domain: { eq: $domain } } } }
            sort: ["release_date:desc"]
            pagination: { limit: $limit, start: $start }
        ) {
            title
            release_date
            documentId
            links {
                documentId
                url
                platform
            }
            cover {
                formats
                url
            }
            artists {
                name
            }
        }
    }
`

type ReleasesType = {
    domain: string
}

const Releases = ({ domain }: ReleasesType) => {
    const [albums, setAlbums] = useState<Album[]>([])
    const [latestReleasedAlbum, setLatestReleasedAlbum] =
        useState<Album | null>(null)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(4)
    const { config } = useConfig(domain)
    const trackLink = useTracking()

    const [merch, setMerch] = useState<
        { title: string; url: string; image: string }[]
    >([])
    const [fetchReleases, { data, loading }] = useLazyQuery<AlbumsData>(
        GET_RELEASES,
        { fetchPolicy: 'network-only' }
    )

    const loadAlbums = useCallback(
        (start: number, limit: number) => {
            fetchReleases({ variables: { domain, limit, start } })
        },
        [domain, fetchReleases]
    )

    useEffect(() => {
        if (domain) loadAlbums(0, 4)
    }, [domain, loadAlbums])

    useEffect(() => {
        if (data) {
            const newAlbums = data.albums || []
            if (newAlbums.length > 0) {
                if (!latestReleasedAlbum) {
                    // Set the latest release as the first album
                    setLatestReleasedAlbum(newAlbums[0])
                    setAlbums(newAlbums.slice(1)) // Skip the latest release for the grid
                } else {
                    setAlbums((prev) => [
                        ...prev,
                        ...newAlbums.filter(
                            (album) =>
                                !prev.some(
                                    (existing) =>
                                        existing.documentId === album.documentId
                                )
                        ),
                    ])
                }
                setHasMore(newAlbums.length === 4 || newAlbums.length === 12) // Check if the batch is full
            } else {
                setHasMore(false) // No more albums
            }
        }
    }, [data])

    useEffect(() => {
        if (!config?.shopify_collection) {
            console.warn('No Shopify collection specified in config')
            return
        }

        const merchUrl = `${API_ORIGIN}/shopify/collection/${config.shopify_collection}`
        console.log(`Fetching merch from: ${merchUrl}`)

        fetch(merchUrl)
            .then((res) => {
                console.log(
                    `Received response: ${res.status} ${res.statusText}`
                )
                return res.json()
            })
            .then((data) => {
                console.log('Fetched merch data:', data)
                setMerch(data)
            })
            .catch((error) => {
                console.error('Error fetching merch:', error)
                setMerch([])
            })
    }, [config])

    const handleLinkClick = (link: LinkType) => {
        trackLink(link.documentId, domain)
    }

    usePageMeta(
        config &&
            latestReleasedAlbum &&
            `${config?.name} | ${latestReleasedAlbum?.title}`,
        null
    )

    const handleLoadMore = useCallback(() => {
        loadAlbums(offset, 12)
        setOffset((prev) => prev + 12)
    }, [offset, loadAlbums])

    const sortedLinks = useMemo(() => {
        return [...(latestReleasedAlbum?.links || [])].sort(
            (a, b) =>
                storeOrder.indexOf(a.platform) - storeOrder.indexOf(b.platform)
        )
    }, [latestReleasedAlbum])

    return (
        <Container>
            {latestReleasedAlbum && (
                <LatestReleaseContainer>
                    <Title>{latestReleasedAlbum.title}</Title>
                    <AlbumCover
                        src={`${CONTENT_ORIGIN}${latestReleasedAlbum.cover?.url}`}
                        alt={`Cover art of ${latestReleasedAlbum.title || 'Unknown Album'}`}
                    />
                    <StoreList>
                        {sortedLinks.map((link) => (
                            <StoreCard
                                key={link.documentId}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => handleLinkClick(link)}
                            >
                                <StoreLogo
                                    src={`/images/logo_${link.platform.toLowerCase()}_ondark.svg`}
                                    alt={`${link.platform} logo`}
                                />
                                <CallToAction>
                                    {storeButtonLabels[link.platform] ||
                                        'Besuchen'}
                                </CallToAction>
                            </StoreCard>
                        ))}
                    </StoreList>
                </LatestReleaseContainer>
            )}
            <Title>Weitere Releases</Title>
            <ReleasesContainer>
                {albums.map((release) => (
                    <CoverLink
                        key={release.documentId}
                        to={`/release/${release.documentId}`}
                    >
                        <CoverImage
                            src={`${CONTENT_ORIGIN}${release.cover?.formats?.small?.url}`}
                            alt={`Cover art of ${release.title || 'Unknown Album'}`}
                            loading="lazy"
                        />
                    </CoverLink>
                ))}
            </ReleasesContainer>
            {hasMore && !loading && (
                <ShowMoreButton onClick={handleLoadMore}>
                    Mehr anzeigen
                </ShowMoreButton>
            )}
            {merch.length >= 1 && (
                <>
                    <Title>Merch</Title>
                    <MerchContainer>
                        {merch.map((item, index) => (
                            <MerchItem
                                key={index}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <CoverImage src={item.image} alt={item.title} />
                            </MerchItem>
                        ))}
                    </MerchContainer>
                </>
            )}
            {loading && <p>Loading...</p>}
        </Container>
    )
}

export default Releases
