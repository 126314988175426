import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
    height: 35px;
    fill: ${(props) => props.theme.text}; /* Match SVG color to text color */
    transition: fill 0.3s ease;

    &:hover {
        fill: ${(props) => props.theme.hoverBackground}; /* Add hover effect */
    }
`

export type SocialLinkProps = {
    svg: ReactElement // Pass the SVG markup directly as a React element
    link: any
}

export default function SociaLink({
    svg,
    link,
}: SocialLinkProps): ReactElement {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            {React.cloneElement(svg, { className: Svg })}
        </a>
    )
}
